import { CSSObject } from '@chakra-ui/react';
import { CustomButtonProps } from '@/components/Button/Button';

export const BoxWrapper: CSSObject = {
  bg: 'transparent',
  mb: '3',
  pb: '2',
  mx: ['-2', '0'],
  px: ['2', '0'],
  mt: '2',
  gap: '1',
};

export const buttonTabBarProps: CustomButtonProps = {
  variant: 'tab',
};

export const buttonTabBarMeetingsProps: CustomButtonProps = {
  variant: 'tab',
};
