import React from 'react';
import { CSSObject, Icon } from '@chakra-ui/react';
import { CheckCircle } from '@styled-icons/bootstrap/CheckCircle';
import { Alert } from '@styled-icons/octicons/Alert';

export const EstReturnsLabel: CSSObject = {
  color: 'alpha.700',
  fontWeight: 'extrabold',
  lineHeight: 'normal',
  alignItems: 'center',
  px: '1',
  width: 'auto',
  borderLeft: '1px solid',
  borderColor: 'gamma.800',
};

export const PendingBetContainer: CSSObject = {
  bg: 'orange.500',
  borderRadius: 'base',
  w: 'full',
  color: 'white',
  justifyContent: 'start',
  boxShadow:
    '0px 2px 4px 0px rgba(0, 0, 0, 0.20), 0px 1px 0px 0px rgba(255, 255, 255, 0.20) inset',
};

export const BetApprovalText: CSSObject = {
  fontSize: '2xs',
  color: 'white',
  fontWeight: 'medium',
};

export const BetPendingApprovalText: CSSObject = {
  fontSize: '2xs',
  fontWeight: 'medium',
};

export const BetRejectedText: CSSObject = {
  fontSize: '2xs',
  color: 'white',
  fontWeight: 'medium',
};

export const ApprovedBetContainer: CSSObject = {
  color: 'white',
  bg: 'green.500',
  borderRadius: 'base',
  boxShadow:
    '0px 2px 4px 0px rgba(0, 0, 0, 0.20), 0px 1px 0px 0px rgba(255, 255, 255, 0.20) inset',
  w: 'full',
  justifyContent: 'start',
};

export const RejectedBetContainer: CSSObject = {
  bg: 'red.600',
  boxShadow:
    '0px 2px 4px 0px rgba(0, 0, 0, 0.20), 0px 1px 0px 0px rgba(255, 255, 255, 0.20) inset',
  borderRadius: 'base',
  w: 'full',
  justifyContent: 'start',
};

export const FooterContainer: CSSObject = {
  bg: 'gamma.300',
  px: '0',
  h: '6',
  overflow: 'hidden',
  borderRadius: 'base',
  fontSize: '2xs',
  boxShadow:
    '0px 3px 4px 0px rgba(0, 0, 0, 0.15), 0px 1px 0px 0px rgba(255, 255, 255, 0.25) inset',
};

export const FooterText: CSSObject = {
  fontWeight: 'semibold',
  color: 'gamma.800',
  lineHeight: 'normal',
};

export const IconApprovedBet = () => (
  <Icon as={CheckCircle} boxSize="4" mr="2" />
);

export const IconRejectedBet = () => <Icon as={Alert} boxSize="4" mr="2" />;
