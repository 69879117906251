import React from 'react';
import { Global } from '@emotion/react';
import bg from '@/assets/questbet/images/backgrounds/bgtop.png';

export default function index() {
  return (
    <Global
      styles={{
        body: {
          backgroundImage: `url(${bg}), linear-gradient(180deg, #F7F4E4 40%, #69D0E8 100%)`,
          backgroundPosition: 'center top, center',
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover, 768px',
          backgroundAttachment: 'fixed',
        },
      }}
    />
  );
}
