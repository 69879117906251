import { CSSObject } from '@chakra-ui/react';

export const IconCircleOutline: CSSObject = {
  boxSize: '6',
  color: 'beta.500',
};

export const DrawerHeaderContainer: CSSObject = {
  p: 0,
  borderBottom: '0',
};
