import { CSSObject } from '@chakra-ui/react';
import { ExoticsActionSchema } from '@/views/races/bets/Exotics/components/Action/styles/Action.styles';

export const exoticsActionStyles: ExoticsActionSchema = {
  buttonExoticsActionProps: {
    variant: 'primary',
    boxSize: '30px',
    minWidth: '7',
    minHeight: '7',
    fontSize: 'xs',
    borderRadius: 'md',
  },
};

export const FlexOdds: CSSObject = {
  fontWeight: 'black',
  alignItems: 'center',
  justifyContent: 'center',
  px: '1',
  py: ['1', null, '2'],
  minW: '10',
  textAlign: 'center',
  whiteSpace: 'nowrap',
  mr: '0',
  h: '30px',
  w: '12',
  border: 'none',
  fontSize: 'md',
  textShadow: '1px 1px 0px rgba(255, 255, 255, 0.20)',
  color: 'blackAlpha.600',
  bg: 'beta.100',
  borderRadius: 'md',
  fontFamily: 'CrimsonPro',
};

export const ActionsContainer: CSSObject = {
  display: 'flex',
  flexDir: 'row',
  gap: '1',
  mb: '1',
};

export const HStackActions: CSSObject = {
  gap: '1',
  alignItems: ['flex-end', 'center'],
};
