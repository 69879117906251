import { CSSObject } from '@chakra-ui/react';
import { RaceTableSchema } from '@/views/races/RaceMeetings/RaceTable/styles/RaceTable/RaceTable.styles';

export const FlexHeadingRow: CSSObject = {};

export const raceTableStyles: RaceTableSchema = {
  iconSvgMoneyBackProps: { color: 'beta.600', mr: ['20', 0] },
  afterLinkRaceItemProps: { bg: [null, null, 'beta.600'] },
};

export const FlexHeading: CSSObject = {
  bg: 'beta.700',
  boxShadow: '0px 2px 5px rgba(0, 0, 0, 0.15)',
  color: 'white',
  top: ['12', '84px'],
  borderTopLeftRadius: 'md',
  borderTopRightRadius: 'md',
  position: 'unset',
};

export const TextHeading: CSSObject = {
  fontFamily: 'Roboto',
  fontSize: '13px',
  fontStyle: 'normal',
  fontWeight: 600,
  lineHeight: '133%',
};

export const IconHeading: CSSObject = {
  boxSize: '1.8rem',
  color: 'gamma.700',
  bg: 'gamma.500',
  borderRadius: ['base', 'sm'],
  p: '1.5',
  mr: '2',
};

export const BoxPageWrapper: CSSObject = {
  mx: '4',
  mb: '0',
  borderRadius: 'md',
};

export const BoxTableWrapper: CSSObject = {
  borderBottomLeftRadius: 'md',
  borderBottomRightRadius: 'md',
  p: '0',
  bg: 'beta.300',
};

export const TextVenueName: CSSObject = {
  fontWeight: 'medium',
  color: 'gray.700',
};

export const TextVenueCountry: CSSObject = {
  fontWeight: 'medium',
  color: 'beta.600',
};

export const TextRaceResults: CSSObject = {
  color: 'gray.700',
};

export const BadgeRaceItem = ({ state }: any): CSSObject => ({
  borderRadius: 'base',
  bg: (() => {
    if (state === 'result') {
      return 'black';
    }
    return 'blackAlpha.400';
  })(),
});

export const LinkRaceItem = () => ({
  borderColor: 'beta.600',
  bg: 'transparent',

  _hover: {
    background: ['transparent', null, 'beta.400'],
  },
  '.chakra-badge.state-ended': {
    color: 'white',
  },
  '.chakra-badge.state-inprogress': {
    color: 'alpha.800',
  },
  '.chakra-badge.state-prior': {
    bg: 'blackAlpha.400',
    color: 'white',
  },
});

export const FlexRow: CSSObject = {
  bg: 'beta.300',
  maxW: '100%',
  w: '100%',

  _last: {
    '& > *': {
      borderBottom: ['unset', null, '1px'],
      borderBottomColor: [null, null, 'beta.600'],
    },
  },
};

export const FlexButtonWrapper: CSSObject = {
  ml: 'auto',
  flexDir: 'column',
  columnGap: '4',
};

export const BoxVenueName: CSSObject = {
  borderRight: [null, null, '1px'],
  borderColor: [null, null, 'beta.600'],
  borderTop: '1px',
  bg: 'beta.300',
  py: '1.5',
};

export const CenterTH = () => ({
  bg: 'beta.600',
  color: 'white',
  textAlign: 'center',
  fontFamily: 'Roboto',
  fontSize: '10px',
  fontStyle: 'normal',
  fontWeight: 500,
  lineHeight: 'normal',
  py: '2.5',
});

export const ButtonChevron: CSSObject = {
  color: 'alpha.700',
  bg: 'alpha.300',
  borderRadius: 'md',
  boxSize: '5',
  width: '7',
  _first: {
    '&&': {
      mb: '0.5',
    },
  },
};
