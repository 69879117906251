import { CSSObject } from '@chakra-ui/react';
import { CustomButtonProps } from '@/components/Button/Button';

export const BoxWrapper: CSSObject = {
  borderRadius: ['none', 'lg'],
  bg: 'beta.800',
  mb: '1',
  mr: '0',
  ml: '0',
  px: ['2', '3'],
  pt: '1',
  mt: '3',
  gap: '1',
  height: 'auto',
  boxShadow:
    '0px 1px 0px 0px var(--bc-colors-whiteAlpha-300), 0px 3px 5px 0px var(--bc-colors-blackAlpha-400) inset',
  '.same-race-multi': {
    bg: 'transparent',
  },

  '&.subTabBar': {
    mt: '2',
  },
};

export const buttonTabBarProps: CustomButtonProps = {
  variant: 'tab',
  height: '8',
};

export const buttonTabBarMeetingsProps: CustomButtonProps = {
  variant: 'tab',
  height: '8',
};
