import React from 'react';
import { Box, Image } from '@chakra-ui/react';
import { TTemplate } from '@/helpers/createTemplate';
import sideNavRSamurai from '../../../../assets/questbet/images/sidenav/mobile/sidenav_rhs_samurai.png';
import sideNavLSamurai from '../../../../assets/questbet/images/sidenav/mobile/sidenav_lhs_samurai.png';
import sideNavRocks from '../../../../assets/questbet/images/sidenav/mobile/sidenav_rocks.png';
import sideNavTemple from '../../../../assets/questbet/images/sidenav/mobile/sidenav_temple.png';
import sideNavGround from '../../../../assets/questbet/images/sidenav/mobile/sidenav_ground.png';

export const template: TTemplate = {
  wrapper: (
    <Box>
      <Image
        src={sideNavRSamurai}
        alt="rhsSamurai"
        sx={{ position: 'absolute', bottom: '68px', right: '20px' }}
      />
      <Image
        src={sideNavLSamurai}
        alt="lhsSamurai"
        sx={{ position: 'absolute', bottom: '68px', left: '20px' }}
      />
      <Image
        src={sideNavRocks}
        alt="rocks"
        sx={{ position: 'absolute', bottom: '70px', width: '30%', left: '14%' }}
      />
      <Image
        src={sideNavTemple}
        alt="temple"
        sx={{ position: 'absolute', bottom: '50px', width: '100%' }}
      />
      <Image
        src={sideNavGround}
        alt="ground"
        sx={{ position: 'absolute', bottom: '0', width: '100%' }}
      />
    </Box>
  ),
};

export default template;
