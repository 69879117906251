import { CSSObject } from '@chakra-ui/react';
import { RaceDetailsSchema } from '@/views/races/RaceDetails/styles/RaceDetails.styles';

export const raceDetailsStyles: RaceDetailsSchema = {
  buttonStyledGroupedProps: {
    variant: 'tab',
    boxSize: '30px',
    minWidth: '7',
    minHeight: '7',
    fontSize: 'xs',
    borderRadius: 'md',
    sx: {
      '&[data-hasPromo="true"]': {
        minW: 12,
        '.btn-span': {
          pl: 0.5,
        },
      },
    },
  },
};

export const ButtonGroupWrapper: CSSObject = {
  gap: '1',
};

export const CountdownTimerContainer: CSSObject = {
  '.chakra-badge': {
    boxSizing: 'content-box',
  },
};

export const RaceAndTimerContainer: CSSObject = {
  borderRadius: 'base',
  bg: ['transparent', 'transparent', 'blackAlpha.400'],
  boxShadow: [
    null,
    null,
    '0px 1px 0px 0px rgba(255, 255, 255, 0.10), 0px 2px 5px 0px rgba(0, 0, 0, 0.35) inset',
  ],
};

export const TextRaceConditions: CSSObject = {
  fontSize: 'xs',
  fontWeight: 'normal',
  _after: {
    content: `""`,
    boxSize: '1',
    borderRadius: 'full',
    bg: 'beta.200',
    pos: 'relative',
    mx: '1.5',
  },
};

export const ResultedStatusLabel: CSSObject = {
  color: 'white',
  bg: '#38a169', // @TODO colour not in palette
};

export const RaceStatusText: CSSObject = {
  color: 'white',
  // @TODO colour not in palette
  bg: 'orange.500',
};

export const RaceDetailsHeading: CSSObject = {
  color: 'white',
  textTransform: 'none',
  fontFamily: 'Roboto',
  fontSize: 'xs',
  fontStyle: 'normal',
  fontWeight: 500,
  lineHeight: 'normal',
  svg: {
    color: 'white',
  },
};

export const RaceRunnerListWrapper: CSSObject = {
  mx: ['2', null, '4'],
  mt: '2',
  py: '2',
};

export const RaceRunnerList: CSSObject = {
  borderRadius: 'base',
  bgGradient: 'linear(232deg, #3f4953, #4b5a6d)', // @TODO colour not in palette
  p: '2',
  boxShadow:
    '0 4px 4px 0 #00000040, inset 0 1px 0 0 #ffffff26, -1px 0 18px 0 #00000026',
};

export const TextTitle: CSSObject = {
  fontFamily: 'Roboto',
  fontSize: 'md',
  fontStyle: 'normal',
  fontWeight: 500,
  lineHeight: 'normal',
  textTransform: 'capitalize',
};

export const RaceName: CSSObject = {
  fontFamily: 'Roboto',
  fontSize: '12px',
  fontStyle: 'normal',
  fontWeight: 700,
  lineHeight: 'normal',
  textTransform: 'capitalize',
};

export const InfoContainer: CSSObject = {
  mb: '0',
  pb: '1.5',
};

export const RaceRunnerHeadingContainer: CSSObject = {
  columnGap: '0',
  '.chakra-switch__track': {
    background: 'gray.400',
    '& .chakra-switch__thumb': {
      background: 'white',
    },
    '&[data-checked]': {
      bgGradient: 'linear(to-b, alpha.500, alpha.600)',
    },
  },
  svg: {
    color: 'white',
  },
};

export const RaceRunnerListHeadings: CSSObject = {
  minH: '36px',
  mx: '2',
  pb: '2',
};

export const RaceRunnerPropositionHeadingContainer: CSSObject = {
  '&&': {
    px: '0',
    w: '12',
  },
  svg: {
    color: 'white',
  },
};
