import { CSSObject } from '@chakra-ui/react';
import { RaceTableSchema } from '@/views/races/RaceMeetings/RaceTable/styles/RaceTable/RaceTable.styles';

export const raceTableStyles: RaceTableSchema = {
  iconSvgMoneyBackProps: { color: 'gray.500', mr: ['20', 0] },
  afterLinkRaceItemProps: { bg: [null, null, 'gray.500'] },
};

export const FlexHeadingRow: CSSObject = {
  bg: 'delta.700',
};

export const FlexHeading: CSSObject = {
  bgGradient: 'linear(to-b, alpha.400, alpha.600)',
  boxShadow: '0px 2px 5px rgba(0, 0, 0, 0.15)',
  color: 'white',
  top: ['12', '84px'],
  borderTopRadius: 'sm',
  position: 'inherit',
  mb: 'unset',
};

export const TextHeading: CSSObject = {
  fontSize: 'sm',
  fontStyle: 'normal',
  fontWeight: 'semibold',
  lineHeight: 'normal',
};

export const IconHeading: CSSObject = {
  boxSize: '7',
  color: 'delta.50',
  bgGradient: 'linear(to-b, delta.300, delta.600)',
  borderRadius: 'md',
  p: '1.5',
  mr: '2',
};

export const BoxPageWrapper: CSSObject = {
  p: '1',
  mx: ['2', '4'],
  mb: '2',
  borderRadius: 'md',
  boxShadow:
    '0px 0px 0px 2px var(--bc-colors-beta-500) inset, 0px 0px 0px 4px var(--bc-colors-alpha-800) inset, 0px 0px 0px 5px rgba(255, 255, 255, 0.30) inset',
};

export const BoxTableWrapper: CSSObject = {
  borderBottomLeftRadius: 'sm',
  borderBottomRightRadius: 'sm',
  p: '0',
};

export const TextVenueName: CSSObject = {
  fontWeight: 'medium',
};

export const TextVenueCountry: CSSObject = {
  fontWeight: 'medium',
  color: 'gray.500',
};

export const TextRaceResults: CSSObject = {
  color: 'gray.700',
};

export const BadgeRaceItem = ({ state }: any): CSSObject => ({
  borderRadius: 'base',
  bg: (() => {
    if (state === 'result') {
      return 'black';
    }
    return 'blackAlpha.400';
  })(),
});

export const LinkRaceItem = ({
  isToteMultiAvailable,
}: {
  isToteMultiAvailable?: boolean;
}) => ({
  bg: isToteMultiAvailable ? 'alpha.500' : 'transparent',
  borderColor: 'gray.300',

  _last: {
    borderRight: 'none',
  },
  _hover: {
    background: 'blackAlpha.100',
  },
  '.chakra-badge.state-ended': {
    color: 'white',
  },
  '.chakra-badge.state-inprogress': {
    color: 'beta.800',
  },
  '.chakra-badge.state-prior': {
    bg: 'blackAlpha.500',
    color: 'white',
  },
});

export const FlexRow: CSSObject = {
  bg: 'white',
  borderRadius: 'unset',
  mb: 'unset',
  _hover: {
    color: 'black',
  },
};

export const FlexButtonWrapper: CSSObject = {
  ml: 'auto',
  flexDir: 'column',
  columnGap: '4',
};

export const BoxVenueName: CSSObject = {
  borderRight: ['none', null, '1px solid', '1px solid'],
  borderColor: [null, null, 'gray.300', 'gray.300'],
  borderTop: '1px',
  bg: 'white',
  py: '1.5',
};

export const CenterTH = () => ({
  bg: 'delta.700',
  color: 'white',
  textAlign: 'center',
  fontSize: '2xs',
  fontStyle: 'normal',
  fontWeight: 'medium',
  lineHeight: 'normal',
  py: '2.5',
});

export const ButtonChevron: CSSObject = {
  color: 'blackAlpha.700',
  bg: 'beta.50',
  borderRadius: 'base',
  boxSize: '5',
  width: '7',
  _first: {
    '&&': {
      mb: '0.5',
    },
  },
};
