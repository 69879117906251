import { CSSObject, BoxProps } from '@chakra-ui/react';

export const FooterMoreOpenText: CSSObject = {
  color: 'alpha.600',
  gap: '3px',
  '&&': {
    px: 0,

    '.collapsed &': { pb: 0 },
  },
};

export const footerWrapperExpanded: BoxProps = {
  px: 0,
  pb: 0,

  sx: {
    '& > div': {
      color: 'gamma.100',
    },
  },
};

export const BoxMoreText: CSSObject = {
  cursor: 'pointer',
};

export const DeductionsText: CSSObject = {
  color: 'alpha.600',
  fontSize: '10px',
  fontFamily: 'Roboto',
  fontWeight: 600,
  mb: '1',
};

export const DividerMulti: CSSObject = {
  borderTop: '1px dashed',
  borderTopColor: 'blackAlpha.500',
  borderBottom: '1px dashed',
  borderBottomColor: 'whiteAlpha.300',
};
