import { CSSObject } from '@chakra-ui/react';
import { CustomButtonProps } from '@/components/Button/Button';

export const buttonAddToBetslipProps: CustomButtonProps = {
  variant: 'solid',
  height: '40px',
};

export const FlexWrapper: CSSObject = {
  bg: '#570300',
  borderBottomRadius: 'lg',
  borderBottom: '1px',
  borderColor: 'gamma.500',
  px: ['3', null, '2.5'],
  '&&': {
    py: '3',
  },
};

export const FlexInputWrapper: CSSObject = {
  label: {
    color: 'white',
    fontWeight: 'semibold',
    mt: '0',
  },

  '&&': {
    '.chakra-form__label': {
      color: 'gamma.200',
    },
    '.chakra-input__group': {
      border: 'none',
      boxShadow: 'none',
      p: '0',
    },
    '.chakra-input__left-addon': {
      w: '10',
      border: 'none',
      fontWeight: 'bold',
      borderLeftRadius: 'md',
    },
    '.chakra-input': {
      w: 'full',
      borderLeftRadius: 'none',
      bg: 'gamma.800',
    },
  },
};

export const SelectionPopupContainer: CSSObject = {
  mt: '2.5',
  bg: 'alpha.500',
  borderX: '1px',
  borderY: 'none',
  borderColor: 'gamma.500',
  borderBottomRadius: 'lg',
  zIndex: '10',
};

export const InfoHeader: CSSObject = {
  borderTop: '4px solid',
  borderColor: 'gamma.500',
  bg: 'alpha.800',
  h: '9',
  px: '4',
  py: '2',
};

export const InfoText: CSSObject = {
  fontSize: 'sm',
  color: 'gamma.500',
  fontFamily: 'accentStaat',
  mt: '-3px',
  '&&': {
    fontWeight: 'normal',
  },
};

export const TextAddToBetslipButton: CSSObject = {
  w: '267px',
  fontWeight: 'black',
};

export const toteMultiListSelections: CSSObject = {
  color: 'white',
  px: '4',
  py: '3',
  display: 'flex',
  fontSize: 'sm',
  fontWeight: 'bold',
  bg: '#570300',
  borderBottom: '1px dashed',
  borderColor: 'white',
  
  sx: {
    '.toteMultiLegNum': {
      mr: '8',
      '& + p > span': {
        ml: '2',
        fontWeight: 'normal',
      },
    },
    '&.last-element': {
      borderBottom: 'none',
    }
  },
};

export const toteMultiListSelectionsIcon: CSSObject = {
  mt: '-7px',
  width: '24px',
  height: '24px',
  color: 'gamma.500',
};