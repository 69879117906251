import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useLocation, useSearchParams } from 'react-router-dom';
import { chakra, Flex, Skeleton, Text } from '@chakra-ui/react';
import { FEATURE_FLAGS } from '@/constants/featureFlags';
import { useQueryOfferings } from '@/api/offerings/offerings.hooks';
import IconSvg from '@/components/common/IconSvg';

const Toggle = chakra(Flex, {
  label: 'desktopToggle-Toggle',
  baseStyle: () => ({
    h: '2.5rem',
    gap: '.5rem',
    display: 'flex',
    flexDir: 'row',
    alignItems: 'center',

    _hover: {
      cursor: 'pointer',
    },
  }),
});

const Label = chakra(Text, {
  label: 'desktopToggle-Label',
  baseStyle: () => ({
    fontFamily: 'ProximaNova',
    fontWeight: 'bold',
    fontSize: '.875rem',
    textTransform: 'uppercase',
    textShadow: '0 -.0625rem 0 rgba(0,0,0,.2)',
    color: 'alpha.100',
    userSelect: 'none',
    transition: 'all 0.25s',

    _hover: {
      color: 'gamma.400',
      textShadow: '0 0 .75rem rgba(255,195,28,.6)',
    },

    '&[data-selected="true"], &[data-selected="true"]:hover': {
      color: 'beta.400',
      textShadow: '0 0 .75rem rgba(255,130,235,.6)',
    },
  }),
});

const Switcher = chakra(Flex, {
  label: 'desktopToggle-Switcher',
  baseStyle: () => ({
    w: '6rem',
    h: '2.5rem',
    position: 'relative',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    p: '0.3125rem',
    gap: '0.125rem',
    boxShadow: '0 0.3125rem 0.375rem rgba(0, 0, 0, 0.3)',
    borderRadius: '0.375rem',
    bg: ['none', null, null, 'rgba(0, 0, 0, 0.20)'],

    _before: {
      content: '""',
      position: 'absolute',
      right: 0,
      bottom: 0,
      zIndex: -1,
      w: '100%',
      h: '100%',
      top: 0,
      left: 0,
      borderRadius: '0.375rem',
      bgGradient: 'linear(180deg, #ff5887, #f223f7)',
    },

    _after: {
      content: '""',
      position: 'absolute',
      right: 0,
      bottom: 0,
      zIndex: -1,
      width: 'calc(100% - .25rem)',
      height: 'calc(100% - .25rem)',
      top: '.125rem',
      left: '.125rem',
      borderRadius: '.3125rem',
      background: '#482394',
    },
  }),
});

const Cap = chakra(Flex, {
  label: 'desktopToggle-Cap',
  baseStyle: () => ({
    w: '.375rem',
    h: '.625rem',
    borderRadius: '.5rem 0 0 .5rem',
    bg: 'beta.300',

    '&[data-end]': {
      borderRadius: '0 .5rem .5rem 0',
    },
  }),
});

const Slot = chakra(Flex, {
  label: 'desktopToggle-Slot',
  baseStyle: () => ({
    w: '1.375rem',
    h: '1.875rem',
    display: 'flex',
    flexDirection: 'column',
    gap: '1rem',
    alignItems: 'center',
    justifyContent: 'flex-start',
    zIndex: 1,
    overflow: 'hidden',
    boxShadow:
      '0 0.0625rem 0 hsla(0, 0%, 100%, 0.08), inset 0 0.125rem 0.25rem rgba(0, 0, 0, 0.3)',
    borderRadius: '0.25rem',
    background: 'rgba(0, 0, 0, 0.3)',

    '& svg': {
      transition: 'all .4s',
      userSelect: 'none',
      boxSize: '30px',
    },

    '&:nth-child(2) svg': {
      transitionDelay: '.2s',
    },

    '&:nth-child(3) svg': {
      transitionDelay: '.3s',
    },

    '&[data-selected="true"] svg': {
      transform: 'translateY(-138px)',
    },
  }),
});

const Panel = chakra(Flex, {
  label: 'desktopToggle-Panel',
  baseStyle: () => ({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    pb: '.75rem',
    pt: ['.75rem', null, null, 'unset'],
    w: '100%',
    boxShadow: [
      '0 .1875rem .3125rem rgba(0, 0, 0, .25), inset 0 0 0 .0625rem hsla(0, 0%, 100%, .1)',
      null,
      null,
      'none',
    ],
    borderRadius: '.3125rem',
    backdropFilter: ['blur(.25rem)', null, null, 'none'],
    background: ['rgba(129, 83, 255, .5)', null, null, 'none'],
  }),
});

const Container = chakra(Flex, {
  label: 'desktopToggle-Container',
  baseStyle: () => ({
    display: 'flex',
  }),
});

export default function Toggler() {
  const [searchParams, setSearchParams] = useSearchParams();
  const { category } = {
    category: searchParams.get('category'),
  };
  const { data, isInitialLoading } = useQueryOfferings();
  const hasSports = FEATURE_FLAGS.HAS_SPORTS_VIEW;
  const location = useLocation();
  const isHomePage = location.pathname === '/';

  // If it's not the home page, don't render anything
  if (!isHomePage) {
    return null;
  }

  if (hasSports && isInitialLoading) {
    return <Skeleton sx={{ h: '44px', mb: '2.5' }} />;
  }

  if (hasSports && !data?.offered_sports?.length) {
    return <></>;
  }

  if (
    !FEATURE_FLAGS.HAS_RACE_VIEW ||
    !hasSports ||
    (hasSports && !data?.offered_sports?.length)
  )
    return <></>;

  return (
    <Container>
      <Panel>
        <Toggle
          onClick={() => {
            setSearchParams({
              category: !category || category === 'racing' ? 'sport' : 'racing',
            });
          }}
        >
          <Label
            data-cy="racing-toggle-text"
            data-selected={category !== 'sport'}
          >
            <FormattedMessage id="racing" defaultMessage="Racing" />
          </Label>
          <Switcher>
            <Cap />
            <Slot data-selected={category === 'sport'}>
              <IconSvg name="horse" aria-hidden />
              <IconSvg name="soccer-1" aria-hidden />
              <IconSvg name="horse" aria-hidden />
              <IconSvg name="soccer-1" aria-hidden />
              <IconSvg name="horse" aria-hidden />
              <IconSvg name="soccer-1" aria-hidden />
            </Slot>
            <Slot data-selected={category === 'sport'}>
              <IconSvg name="horse" aria-hidden />
              <IconSvg name="soccer-1" aria-hidden />
              <IconSvg name="horse" aria-hidden />
              <IconSvg name="soccer-1" aria-hidden />
              <IconSvg name="horse" aria-hidden />
              <IconSvg name="soccer-1" aria-hidden />
            </Slot>
            <Slot data-selected={category === 'sport'}>
              <IconSvg name="horse" aria-hidden />
              <IconSvg name="soccer-1" aria-hidden />
              <IconSvg name="horse" aria-hidden />
              <IconSvg name="soccer-1" aria-hidden />
              <IconSvg name="horse" aria-hidden />
              <IconSvg name="soccer-1" aria-hidden />
            </Slot>

            <Cap data-end />
          </Switcher>
          <Label
            data-cy="sports-toggle-text"
            data-selected={category === 'sport'}
          >
            <FormattedMessage id="sport" defaultMessage="Sport" />
          </Label>
        </Toggle>
      </Panel>
    </Container>
  );
}
