import { CSSObject } from '@chakra-ui/react';
import { CustomButtonProps } from '@/components/Button/Button';

export const buttonTabBarProps: CustomButtonProps = {
  variant: 'tab',
  fontSize: '2xs',
  minH: '7',
};

export const BoxWrapper: CSSObject = {
  borderRadius: ['none', 'base'],
  mb: '0',
  mx: '0',
  mt: '0',
  px: '0',
  gap: '1',
  pb: '2',
};

export const buttonTabBarMeetingsProps: CustomButtonProps = {
  variant: 'tab',
  fontSize: '2xs',
  minH: '7',
};
