import { ButtonProps, CSSObject, theme } from '@chakra-ui/react';
import { colorScheme } from '@/helpers/colorScheme';

const active: CSSObject = {
  bgGradient: 'linear(to-b, alpha.800, alpha.800)',
  boxShadow:
    '0px 4px 4px 0px rgba(0, 0, 0, 0.25), 0px 3px 0px 0px rgba(0, 0, 0, 0.30) inset',
  color: 'alpha.300',
  textShadow: '0px 1px 3px 0px rgba(0, 0, 0, 0.45)',
};

export const primary = {
  bgGradient: 'linear(to-b, alpha.400, alpha.500)',
  boxShadow:
    '0px 4px 4px 0px rgba(0, 0, 0, 0.25), 0px 3px 0px 0px #480, 0px 1px 0px 0px rgba(255, 255, 255, 0.40) inset, 0px 0px 0px 1px rgba(255, 255, 255, 0.20) inset',
  color: 'alpha.800',
  lineHeight: 'none',
  pos: 'relative',
  textTransform: 'uppercase',
  fontSize: 'xs',
  fontWeight: 'black',
  borderRadius: 'md',
  textShadow: '1px 0px 0px rgba(255, 255, 255, 0.45)',

  '&:hover, &:focus': {
    bgGradient: 'linear(to-b, alpha.100, alpha.100)',
    textShadow: 'none',
    boxShadow:
      '0px 4px 4px 0px rgba(0, 0, 0, 0.25), 0px 3px 0px 0px #480, 0px 1px 0px 0px rgba(255, 255, 255, 0.40) inset, 0px 0px 0px 1px rgba(255, 255, 255, 0.20) inset',
    color: 'alpha.800',
  },

  '&:active, &[data-active="true"]': {
    ...active,
  },
  _disabled: {
    opacity: 0.4,
    cursor: 'not-allowed',
    boxShadow: 'none',
    pointerEvents: 'none',
  },
};

export const Button = {
  baseStyle: {},
  sizes: {
    lg: { fontSize: 'lg' },
    md: { fontSize: 'md' },
    sm: { fontSize: 'sm' },
    xs: { fontSize: 'xs' },
  },
  variants: {
    solid: (props: ButtonProps) => ({
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      ...theme.components.Button.variants.solid(props as any),
      ...primary,

      ...(props.colorScheme && colorScheme(props)),
    }),
    primary: (props: ButtonProps) => ({
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      ...theme.components.Button.variants.solid(props as any),
      ...primary,

      ...(props.colorScheme && colorScheme(props)),
    }),
    secondary: (props: ButtonProps) => ({
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      ...theme.components.Button.variants.solid(props as any),

      ...(props.colorScheme && colorScheme(props)),
    }),
    outline: (props: ButtonProps) => ({
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      ...theme.components.Button.variants.outline(props as any),
      borderColor: 'white',
      border: '2px',
      color: 'white',
      fontWeight: 'bold',
      lineHeight: '1',
      borderRadius: 'full',
      textTransform: 'uppercase',

      '&:active, &:hover': {
        bg: 'white',
        color: 'beta.500',
      },
    }),
    unstyled: () => ({
      height: 'auto',
      fontWeight: 'inherit',
      cursor: 'auto !important',
    }),
    filter: {
      border: '2px',
      borderColor: 'blackAlpha.400',
      borderRadius: 'base',
      color: 'alpha.800',
      fontSize: '2xs',
      fontWeight: 'bold',
      h: 'auto',
      minW: 'auto',
      px: '2.5',
      py: '1',
      textTransform: 'uppercase',

      _hover: {
        color: '#2f8f0a',
      },

      _active: {
        borderColor: 'beta.900',
        bg: 'beta.900',
        color: 'white',
      },
    },
    segment: {
      borderRadius: 'base',
      fontSize: '2xs',
      fontWeight: 'bold',
      h: 'auto',
      px: '3',
      py: '3px',
      textTransform: 'uppercase',

      _hover: {
        color: 'alpha.500',
      },

      _active: {
        bgGradient: 'linear(225deg, alpha.500, alpha.500)',
        boxShadow:
          '0px 1px 0px 0px rgba(255, 255, 255, 0.60) inset, 0px 1px 2px 0px rgba(0, 0, 0, 0.25)',
        color: 'blackAlpha.700',
        textShadow: '0px 1px 0px rgba(255, 255, 255, 0.30)',
      },
    },
    tab: {
      minW: 'unset',
      color: 'blackAlpha.800',
      textShadow: '0px 1px 0px rgba(255, 255, 255, 0.30)',
      fontFamily: 'roboto',
      fontSize: '10px',
      fontWeight: 900,
      lineHeight: '14px',
      bgGradient: 'linear(to-b, alpha.400, alpha.500)',
      h: '22px',
      px: '2',
      borderRadius: 'base',
      textTransform: 'uppercase',
      mb: '2',
      boxShadow:
        '0px 1px 1px 0px rgba(255, 255, 255, 0.40) inset, 0px 3px 4px 0px rgba(0, 0, 0, 0.35), 0px 0px 4px 0px rgba(255, 255, 255, 0.50) inset',
      contain: 'paint',

      '&[data-active="true"]': {
        bgGradient: 'linear(to-b, blackAlpha.500, blackAlpha.300)',
        color: 'white',
        boxShadow:
          '0px 2px 4px 0px rgba(0, 0, 0, 0.20) inset, 0px 1px 0px 0px rgba(255, 255, 255, 0.10)',
      },
    },
  },
  defaultProps: {
    size: 'md',
    p: '5',
    colorScheme: null,
  },
};
