import { ButtonProps, theme } from '@chakra-ui/react';
import { colorScheme } from '@/helpers/colorScheme';

const primary = {
  fontWeight: 'extrabold',
  fontSize: 'xs',
  bgGradient: 'linear(180deg, alpha.600 0%, alpha.700 100%)',
  borderRadius: 'base',
  color: 'white',
  textTransform: 'uppercase',
  boxShadow: '0px 2px 5px rgba(0, 0, 0, 0.15)',
  h: '9',

  _hover: {
    bgGradient: 'linear(180deg, alpha.800, alpha.800)',
    boxShadow: '0px 2px 5px rgba(0, 0, 0, 0.15)',
  },
  '&:active, &[data-active="true"]': {
    bgGradient: 'linear(180deg, alpha.200, alpha.200)',
    boxShadow: '0px 3px 4px 0px #000 inset',
    color: 'alpha.700',
  },
};

export const Button = {
  baseStyle: {},
  sizes: {
    lg: { fontSize: 'lg' },
    md: { fontSize: 'md' },
    sm: { fontSize: 'sm' },
    xs: { fontSize: 'xs' },
  },
  variants: {
    solid: (props: ButtonProps) => ({
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      ...theme.components.Button.variants.solid(props as any),
      ...primary,

      ...(props.colorScheme && colorScheme(props)),
    }),
    primary: (props: ButtonProps) => ({
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      ...theme.components.Button.variants.solid(props as any),
      ...primary,

      ...(props.colorScheme && colorScheme(props)),
    }),
    secondary: (props: ButtonProps) => ({
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      ...theme.components.Button.variants.solid(props as any),
      bg: 'gamma.400',
      boxShadow:
        '0px 1px 0px 0px rgba(255, 255, 255, 0.45) inset, 0px 2px 3px 0px rgba(0, 0, 0, 0.15)',
      color: 'gamma.800',
      _hover: {
        bg: 'gamma.200',
      },
      _disabled: {
        pointerEvents: 'none',
      },

      ...(props.colorScheme && colorScheme(props)),
    }),
    tertiary: (props: ButtonProps) => ({
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      ...theme.components.Button.variants.solid(props as any),
      borderRadius: 'base',
      border: '2px',
      // TODO: no token found
      borderColor: '#FFF8E7',
      // TODO: no token found
      bgGradient: 'linear(180deg, #DFB300 0%, #FFD116 100%)',
      boxShadow: '0px 3px 8px 0px rgba(34, 86, 123, 0.80)',
      color: 'gamma.900',
      textShadow: '0px 1px 1px rgba(255, 255, 255, 0.55)',
      fontWeight: 'extrabold',
      textTransform: 'uppercase',
      padding: '4px 12px',

      '&[data-active="true"]': {
        bg: 'white',
        boxShadow: 'inset 0px 3px 4px #ebd0d0',
      },

      ...(props.colorScheme && colorScheme(props)),
    }),
    outline: (props: ButtonProps) => ({
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      ...theme.components.Button.variants.outline(props as any),
      borderColor: 'white',
      border: '2px',
      color: 'white',
      fontWeight: 'bold',
      lineHeight: '1',
      borderRadius: 'full',
      textTransform: 'uppercase',

      '&:active, &:hover': {
        bg: 'white',
        color: 'beta.500',
      },
    }),
    filter: {
      border: '2px',
      borderColor: 'alpha.300',
      borderRadius: 'base',
      color: 'white',
      fontSize: '2xs',
      fontWeight: 'bold',
      h: 'auto',
      minW: 'auto',
      px: '2.5',
      py: '1',
      textTransform: 'uppercase',
      _hover: {
        color: 'yellow.400',
      },
      _active: {
        border: 'none',
        bg: 'yellow.400',
        color: 'blackAlpha.700',
        boxShadow: '0px 1px 0px 0px rgba(255, 255, 255, 0.60) inset',
      },
    },
    segment: {
      borderRadius: 'base',
      fontSize: '2xs',
      fontWeight: 'black',
      textAlign: 'center',
      lineHeight: '14px',
      h: 'auto',
      px: '3',
      py: '3px',
      textTransform: 'uppercase',
      _hover: {
        color: 'yellow.400',
      },
      _active: {
        bg: 'yellow.400',
        boxShadow:
          '0px 1px 0px 0px rgba(255, 255, 255, 0.60) inset, 0px 1px 2px 0px rgba(0, 0, 0, 0.25)',
        color: 'blackAlpha.700',
        textShadow: '0px 1px 0px rgba(255, 255, 255, 0.30)',
      },
    },
    tab: {
      color: 'white',
      bg: 'linear-gradient(var(--bc-colors-alpha-600) 0%, var(--bc-colors-alpha-700) 100%)',
      fontWeight: 'semibold',
      fontSize: '2xs',
      textTransform: 'uppercase',
      borderRadius: 'base',
      px: '2',
      py: '1',
      minW: 'fit-content',
      h: '6',
      transition: 'all .2s ease-in-out',

      boxShadow: 'inset 0px 2px 4px rgba(0, 0, 0, 0.2)',
      '&:hover, &:focus': {
        color: 'white',
        bg: 'linear-gradient(180deg, var(--bc-colors-alpha-700) 0%, var(--bc-colors-alpha-800) 100%)',
      },

      '&:active, &[data-active="true"]': {
        color: 'gray.600',
        bg: 'gray.300',

        '&:hover, &:focus': {
          color: 'gray.700',
          bg: 'gray.400',
        },
      },
    },
    quaddiesTab: {
      boxShadow: 'none',
      fontSize: 'xs',
      textShadow: 'none',
      fontWeight: 'bold',
      borderRadius: 'none',
      fontFamily: 'body',
      h: '8',
      textTransform: 'uppercase',
      color: 'white',
      borderBottom: '3px solid',
      borderBottomColor: 'transparent',
      px: '3',
      pt: '1',
      _hover: {
        color: 'alpha.400',
      },
      ':active, &[data-active="true"]': {
        color: 'alpha.600',
        fontWeight: 'normal',
        borderBottom: '3px solid',
        borderBottomColor: 'alpha.600',
      },
    },
    unstyled: () => ({
      height: 'auto',
      fontWeight: 'inherit',

      '&&': {
        '&:disabled': {
          bg: 'beta.500',
          color: 'white',
        },
      },
    }),
  },
  defaultProps: {
    size: 'md',
    p: '5',
    colorScheme: null,
  },
};
