import { CSSObject, BoxProps } from '@chakra-ui/react';

export const DeductionsText: CSSObject = {
  color: 'beta.500',
  fontSize: '2xs',
  fontWeight: 'bold',
};
export const DividerMulti: CSSObject = {
  borderStyle: 'dashed',
};
export const FooterMoreOpenText: CSSObject = {
  color: 'beta.500',
  pb: '1',
  '&': {
    px: '0',
  },
  fontSize: 'xs',
  '+ div[class*="myBetsCard-ContentRow"]': {
    position: 'relative',
    borderTop: '1px dashed',
    borderTopColor: 'whiteAlpha.500',
    '&::before': {
      position: 'absolute',
      content: '""',
      width: '100%',
      height: '0',
      top: '0',
      left: '0',
      borderBottom: '1px dashed',
      borderBottomColor: 'blackAlpha.200',
    },
  },
};
export const BoxMoreText: CSSObject = {
  w: '8',
  color: 'beta.500',
};
export const footerWrapperExpanded: BoxProps = {
  pl: '0',
  sx: {
    '& > div': {
      color: 'white',
    },
  },
};
