import { ButtonProps, CSSObject, theme } from '@chakra-ui/react';
import { colorScheme } from '@/helpers/colorScheme';

const active: CSSObject = {
  boxShadow: 'inset 0 3px 3px 3px rgba(0, 0, 0, 0.3)',
  border: '2px',
  bg: 'beta.600',
  color: '#cc952b',
  borderColor: 'transparent',
  fontFamily: 'CrimsonPro',
  fontWeight: 900,
  fontStretch: 'normal',
  fontStyle: 'normal',
  letterSpacing: 'normal',
  textAlign: 'center',
  textShadow: 'none',

  _after: { opacity: 0 },
};

/**
 * This is exported for use in special circumstances only i.e: verification, icon buttons, ...
 * @see src/theme/sterlingparker/components/button.ts for elsewhere
 */
export const primary: CSSObject = {
  bg: 'alpha.700',
  display: 'flex',
  height: '34px',
  py: '0',
  px: '6px',
  justifyContent: 'center',
  alignItems: 'center',
  gap: '6px',
  borderRadius: '5px',
  border: '1px',
  borderColor: 'rgba(255, 255, 255, 0.09)',
  boxShadow:
    '0px 1px 1px 0px rgba(255, 255, 255, 0.70) inset, 0px 3px 4px 0px rgba(0, 0, 0, 0.35), 0px 0px 4px 0px #FFF inset', // no colour token
  pos: 'relative',
  color: 'var(--bc-black-alpha-800, rgba(0, 0, 0, 0.80))',
  textAlign: 'center',
  textShadow: '0px 1px 0px rgba(255, 255, 255, 0.45)',
  fontFamily: 'CrimsonPro',
  fontStyle: 'normal',
  fontWeight: 900,
  lineHeight: '34px',
  textTransform: 'uppercase',

  '.btn-span': { zIndex: 9 },

  _after: {
    content: `''`,
    position: 'absolute',
    inset: 0,
    zIndex: 8,
    bgGradient: 'linear(to-b, alpha.400, alpha.700)',
    transition: 'all .2s ease-in-out',
    m: '1px',
    borderRadius: '5px',
    opacity: 0.8,
  },

  _hover: {
    _after: {
      opacity: 0,
    },
  },

  '&:active, &[data-active="true"]': {
    ...active,
  },

  _disabled: {
    ...active,
    color: 'blackAlpha.600',
    textShadow: '1px 1px 0px rgba(255, 255, 255, 0.20)',
    bg: 'beta.100',
    boxShadow: 'none',
    border: 'none',
    opacity: '1',
    pointerEvents: 'none',
  },
};

const secondary: CSSObject = {
  display: 'flex',
  height: '34px',
  padding: '0 6px',
  justifyContent: 'center',
  alignItems: 'center',
  gap: '6px',

  borderRadius: 'md',
  border: '1px',
  borderColor: 'alpha.700',

  bg: 'var(--gradient-grey, linear-gradient(180deg, #30363F 0%, #21252B 100%))',
  boxShadow:
    'inset 0 0.5px 0 2px rgba(255, 255, 255, 0.15), 0 4px 3px 0 rgba(0, 0, 0, 0.25), inset 0 0 0 2px rgba(0, 0, 0, 0.6)',
  textColor: 'rgba(214, 166, 74, 1)',
  pos: 'relative',

  color: 'var(--bc-black-alpha-800, rgba(0, 0, 0, 0.80))',
  textAlign: 'center',
  textShadow: '0 2px 3px rgba(0, 0, 0, 0.15), 0 1px 0 rgba(255, 255, 255, 0.2)',
  fontFamily: 'CrimsonPro',
  fontStyle: 'normal',
  fontWeight: 900,
  lineHeight: '34px',
  textTransform: 'uppercase',

  '.btn-span': { zIndex: 9 },

  _hover: {
    boxShadow:
      'inset 0px 2px 2px 0px rgba(255, 255, 255, 0.15), 0 3px 10px 0 rgba(0, 0, 0, 0.25), inset 0 0 0 3px rgba(0, 0, 0, 0.6)',
    border: '1px',
    borderColor: 'linear(to-b, alpha.400, alpha.700)',
    bg: 'var(--gradient-grey-light, linear-gradient(225deg, #4B5A6D 0%, #3F4953 100%))',

    _after: { opacity: 0 },
  },

  '&:active, &[data-active]': {
    ...active,
  },

  _disabled: {
    ...active,
    color: 'gray.600',
    bg: 'blackAlpha.400',
  },
};

export const Button = {
  baseStyle: {},
  sizes: {
    lg: { fontSize: 'lg' },
    md: { fontSize: '16px' },
    sm: { fontSize: 'sm' },
    xs: { fontSize: 'xs' },
  },
  variants: {
    solid: (props: ButtonProps) => ({
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      ...theme.components.Button.variants.solid(props as any),
      ...primary,

      ...(props.colorScheme && colorScheme(props)),
    }),
    primary: (props: ButtonProps) => ({
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      ...theme.components.Button.variants.solid(props as any),
      ...primary,

      ...(props.colorScheme && colorScheme(props)),
    }),
    secondary: (props: ButtonProps) => ({
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      ...theme.components.Button.variants.solid(props as any),
      ...secondary,

      ...(props.colorScheme && colorScheme(props)),
    }),
    outline: (props: ButtonProps) => ({
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      ...theme.components.Button.variants.outline(props as any),
      h: '34px',
      borderRadius: '5px',
      border: '2px',
      borderColor: 'white',
      color: 'white',

      textAlign: 'center',
      textShadow: '0px 2px 4px rgba(0, 0, 0, 0.20)',
      fontSize: '16px',
      fontFamily: 'CrimsonPro',
      fontStyle: 'normal',
      fontWeight: 900,
      lineHeight: '14px',
      textTransform: 'uppercase',

      '&:active, &:hover': {
        bg: 'white',
        color: 'beta.500',
      },
    }),
    tab: (props: ButtonProps) => ({
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      ...theme.components.Button.variants.solid(props as any),

      bg: 'alpha.700',
      letterSpacing: 'wider',
      textShadow: '0px 1px 0px rgba(255, 255, 255, 0.3)',
      textTransform: 'uppercase',
      fontSize: '2xs',
      px: '2',
      py: '1',
      borderRadius: '3px',
      boxShadow:
        '0px 1px 2px 0px #f9f17a inset,  0px 3px 4px 0px rgba(0, 0, 0, 0.35), 0px 0px 4px 0px #f9f17a inset',
      h: '22px',
      color: 'var(--chakra-black-alpha-800, rgba(0, 0, 0, 0.80))',
      textAlign: 'center',
      fontFamily: 'Roboto',
      fontStyle: 'normal',
      fontWeight: '900',
      minW: 'fit-content',
      '&:hover, &:focus': {
        bg: 'alpha.500',
      },
      '&[data-closed="true"]': {
        bg: '#4b5a6d', // @TODO colour not in palette
        color: 'rgba(0, 0, 0, 0.48)',
        textShadow: '0 1px 0 rgba(255, 255, 255, 0.1)',
        boxShadow: '0 2px 5px 0 rgba(0, 0, 0, 0.15)',
      },
      '&:active, &[data-active="true"]': {
        bg: 'blackAlpha.500',
        color: 'white',
        boxShadow:
          '0px 2px 4px 0px rgba(0, 0, 0, 0.20) inset, 0px 1px 0px 0px rgba(255, 255, 255, 0.10)',
      },
    }),
    link: (props: ButtonProps) => ({
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      ...theme.components.Button.variants.link(props as any),

      '&&': {
        '&:disabled': {
          color: 'white',
        },
      },
    }),
    unstyled: () => ({
      height: 'auto',
      fontWeight: 'inherit',
      cursor: 'auto !important',
    }),
    filter: {
      border: '2px',
      borderColor: 'whiteAlpha.300',
      borderRadius: 'base',
      color: 'white',
      fontSize: '2xs',
      fontWeight: 'bold',
      h: 'auto',
      minW: 'auto',
      px: '2.5',
      py: '1',
      textTransform: 'uppercase',

      _hover: {
        color: 'alpha.500',
      },

      _active: {
        borderColor: 'currentColor',
        color: 'alpha.500',
      },
    },
    segment: {
      borderRadius: 'base',
      fontSize: '2xs',
      fontWeight: 'bold',
      h: 'auto',
      px: '3',
      py: '3px',
      textTransform: 'uppercase',

      _hover: {
        color: 'alpha.500',
      },

      _active: {
        bgGradient: 'linear(225deg, beta.200, beta.300)',
        boxShadow:
          '0 1px rgba(255, 255, 255, .15) inset, 0 1px 2px rgba(0, 0, 0, .25)',
        color: 'alpha.500',
      },
    },
  },

  defaultProps: {
    size: 'md',
    p: '5',
    colorScheme: null,
  },
};
