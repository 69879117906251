import { CSSObject } from '@chakra-ui/react';
import { ExoticsActionSchema } from '@/views/races/bets/Exotics/components/Action/styles/Action.styles';

export const exoticsActionStyles: ExoticsActionSchema = {
  buttonExoticsActionProps: {
    variant: 'solid',
    fontSize: 'sm',
    fontWeight: 'bold',
    minW: 'auto',
    w: '30px',
    h: '30px',
    lineHeight: 'normal',
  },
};

export const Button = (): CSSObject => ({
  fontSize: 'sm',
  fontWeight: 'bold',
  minW: 'auto',
  w: '30px',
  h: '30px',
  p: 'inherit',
});

export const FlexOdds: CSSObject = {
  fontSize: 'sm',
  fontWeight: 'bold',
  bg: 'gray.400',
  alignItems: 'center',
  w: '12',
  justifyContent: 'center',
  px: '3',
  py: ['1', null, '1.5'],
  minW: '11',
  textShadow: '1px 0px 0px rgba(255, 255, 255, 0.15)',
  textAlign: 'center',
  borderRadius: 'lg',
  whiteSpace: 'nowrap',
  mr: '0',
  mt: ['2', null, 'unset'],
  color: 'gray.600',
  h: '30px',
};

export const ActionsContainer: CSSObject = {
  display: 'flex',
  flexDir: 'row',
  gap: '1',
};

export const CheckboxRunnerBoxed: CSSObject = {
  '.chakra-checkbox__control': {
    color: 'gamma.400',
    borderColor: 'gamma.400',
    border: '1px solid',
    _hover: {
      bg: 'alpha.800',
    },
  },
  _checked: {
    '.chakra-checkbox__control': {
      border: '1px',
      borderColor: 'gamma.400',
      bg: 'alpha.700',
    },
  },

  '.chakra-checkbox__label': {
    marginInlineStart: '0',
  },
  flexDir: 'column',
  gap: '2',
  boxSize: '30px',
  borderRadius: 'base',
  alignItems: 'center',
  justifyContent: 'center',
};

export const HStackActions: CSSObject = {
  gap: '0',
};
