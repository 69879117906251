import { CSSObject } from '@chakra-ui/react';

export const WrapperContent: CSSObject = {
  display: 'flex',
  flexDir: 'column',
  py: ['4', null, '10'],
  px: ['2', null, '10'],
  flex: [null, null, 1],
  justifyContent: [null, null, 'center'],
  maxW: ['100vw', null, '500px'],
  bg: 'alpha.700',
  borderTop: ['2px', '2px', '0px'],
  borderColor: 'beta.500',
  borderRightRadius: ['unset', null, 'lg'],
  '.chakra-form__label': {
    color: 'white',
  },
  'input:-webkit-autofill, input:-webkit-autofill:focus': {
    transition: 'background-color 0s 600000s, color 0s 600000s',
  },
  '.chakra-input__right-element': {
    color: 'beta.500',
    '.chakra-button': {
      color: 'beta.400',
    },
  },
  '.chakra-form__error-message': {
    bg: 'red.600',
    boxShadow:
      '0px 2px 4px 0px rgba(0, 0, 0, 0.20), 0px 1px 0px 0px rgba(255, 255, 255, 0.20) inset',
    borderRadius: 'base',
    p: '1',
    color: 'white',
    fontWeight: 'medium',
    fontSize: 'xs',
  },
  '#title-dropdown-select, #state-select, #suburb-input, #postcode-input, #residential-input, #streetName-input, #streetNumber-input':
    {
      borderRadius: 'md',
      bg: 'alpha.300',
      color: 'white',
      border: 'none',
      option: {
        color: 'black',
      },
      fontSize: 'xs',
      fontWeight: 'normal',
    },
  '.chakra-select__icon-wrapper': {
    color: 'beta.400',
  },
  '.btn-span': {
    fontWeight: 'bold',
  },
};

export const Title: CSSObject = {
  color: 'white',
  fontSize: 'xs',
  fontStyle: 'normal',
  fontWeight: '600',
  mb: '4',
  mt: [null, null, '9'],
};
