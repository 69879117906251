import { CSSObject } from '@chakra-ui/react';
import { CustomButtonProps } from '@/components/Button/Button';

export const buttonAddToBetslipProps: CustomButtonProps = {
  variant: 'tertiary',
  fontSize: 'sm',
  h: '12',
};

export const FlexWrapper: CSSObject = {
  px: ['3', null, '2.5'],
  '&&': {
    py: '3',
  },
};

export const FlexInputWrapper: CSSObject = {
  color: 'white',
  '.chakra-form__label': {
    color: 'white',
  },
  '.chakra-input__group': {
    borderRadius: 'base',
    bgGradient: 'linear(to-b, beta.300, beta.500)',
    boxShadow: '0px 1px 2px 0px rgba(0, 0, 0, 0.25)',
    border: 'none',
    '.chakra-input': {
      borderRightRadius: 'base',
      bg: 'alpha.700',
      border: 'none',
      m: '2px',
      fontSize: 'sm',
      fontWeight: 'bold',
      color: 'beta.300',
      boxShadow: 'none',
      _placeholder: {
        fontWeight: 'bold',
        color: 'beta.200',
      },
      ':hover, :focus': {
        bg: 'alpha.500',
      },
    },
    '.chakra-input__left-addon': {
      borderLeftRadius: 'base',
      bg: 'transparent',
      bgGradient: 'none',
      fontSize: 'sm',
      fontWeight: 'black',
      h: 'auto',
      boxShadow: 'none',
      color: 'alpha.700',
      textShadow: '0px 1px 0px rgba(255, 255, 255, 0.40)',
    },
  },
};

export const SelectionPopupContainer: CSSObject = {
  mt: '2',
  bg: 'alpha.500',
  borderRadius: 'base',
  boxShadow: '0px -4px 6px 0px rgba(0, 0, 0, 0.15)',
  zIndex: '10',
};

export const InfoHeader: CSSObject = {
  bgGradient: 'linear(to-b, #FFE6B6, beta.500)',
  h: '7',
  px: '2',
  py: '1.5',
};

export const InfoText: CSSObject = {
  fontSize: 'xs',
  color: 'beta.600',
  fontWeight: 'medium',
};

export const AddToBetslipButton: CSSObject = {
  fontSize: 'sm',
  bg: 'beta.600',
  color: 'blackAlpha.600',
  fontFamily: 'Caveman',
  borderRadius: 'base',
  border: '2px',
  borderColor: 'beta.400',
  boxShadow:
    '0px 4px 5px rgba(0, 0, 0, 0.25), inset 0px 4px 5px rgba(0, 0, 0, 0.15)',
  _hover: {
    bg: 'beta.700',
    borderColor: 'transparent',
  },
  _active: {
    bg: 'alpha.600',
    color: 'white',
    boxShadow: 'inset 0px 2px 5px rgba(0, 0, 0, 0.5)',
  },
};

export const TextAddToBetslipButton: CSSObject = {
  w: '267px',
  fontFamily: 'Fraunces',
  fontWeight: 'bold',
  color: 'beta.400',
  textShadow: '0px 1px 2px rgba(0, 0, 0, 0.55)',
};
