import { SRMInfoSchema } from './SRMInfo.styles';

export const srmInfoStyles: SRMInfoSchema = {
  runnerDetails: {
    fontSize: 'sm',
    fontWeight: 'bold',
    color: 'gamma.800',
  },
  placeNumber: {
    fontWeight: 'extrabold',
    fontSize: 'sm',
    color: 'gamma.800',
    position: 'relative',
    sx: {
      '> span': {
        fontSize: '2xs',
        fontWeight: 'medium',
        position: 'absolute',
        top: 'px',
        pl: '1',
      },
    },
  },
  srmContainer: {
    w: 'full',
    flexDir: 'column',
    py: '2',
    borderBottom: '1px dashed',
    borderBottomColor: 'rgba(0, 0, 0, 0.25)',
    _first: {
      borderTop: '1px dashed',
      borderTopColor: 'rgba(0, 0, 0, 0.25)',
    },
  },
  eventMarketName: {
    fontSize: 'xs',
    fontWeight: 'bold',
    textTransform: 'capitalize',
  },
  eventSubtitle: {
    fontSize: 'xs',
    fontWeight: 'bold',
  },
};
