import { ButtonProps, theme } from '@chakra-ui/react';
import { colorScheme } from '@/helpers/colorScheme';

const primary = {
  fontWeight: 'extrabold',
  fontSize: 'xs',
  bgGradient: 'linear(to-b, alpha.500, gamma.500)',
  borderRadius: 'base',
  color: 'gamma.800',
  textTransform: 'uppercase',
  boxShadow: '0px 2px 5px rgba(0, 0, 0, 0.15)',
  h: '9',

  '&:hover': {
    bgGradient: 'linear(to-b, alpha.500, alpha.500)',
    color: 'gamma.600',
    boxShadow: '0px 2px 5px rgba(0, 0, 0, 0.15)',
  },
  '&:active, &[data-active="true"]': {
    bg: 'gamma.700',
    bgGradient: 'linear(to-b, gamma.700, gamma.700)',
    boxShadow: 'inset 0px 2px 5px rgba(0, 0, 0, 0.45)',
    color: 'white',
  },
};

export const Button = {
  baseStyle: {},
  sizes: {
    lg: { fontSize: 'lg' },
    md: { fontSize: 'md' },
    sm: { fontSize: 'sm' },
    xs: { fontSize: 'xs' },
  },
  variants: {
    solid: (props: ButtonProps) => ({
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      ...theme.components.Button.variants.solid(props as any),
      ...primary,

      ...(props.colorScheme && colorScheme(props)),
    }),
    primary: (props: ButtonProps) => ({
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      ...theme.components.Button.variants.solid(props as any),
      ...primary,

      ...(props.colorScheme && colorScheme(props)),
    }),
    secondary: (props: ButtonProps) => ({
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      ...theme.components.Button.variants.solid(props as any),
      ...(props.colorScheme && colorScheme(props)),

      borderRadius: '5px',
      border: '2px',
      borderColor: 'beta.400',
      background: 'beta.600',
      boxShadow:
        '0px 4px 5px 0px rgba(0, 0, 0, 0.25), 0px 4px 5px 0px rgba(0, 0, 0, 0.15) inset',
      color: 'blackAlpha.600',
      textAlign: 'center',
      textShadow: '0px 1px 1px rgba(255, 255, 255, 0.35)',
      fontFamily: 'Caveman',
      fontSize: 'sm',
      fontWeight: '400',
      lineHeight: 'normal',
      textTransform: 'uppercase',
    }),
    outline: (props: ButtonProps) => ({
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      ...theme.components.Button.variants.outline(props as any),
      borderColor: 'white',
      border: '2px',
      color: 'white',
      fontWeight: 'bold',
      lineHeight: '1',
      borderRadius: 'full',
      textTransform: 'uppercase',

      '&:active, &:hover': {
        bg: 'white',
        color: 'beta.500',
      },
    }),
    unstyled: () => ({
      height: 'auto',
      fontWeight: 'inherit',
      cursor: 'auto !important',
    }),
    filter: {
      border: '2px',
      borderColor: 'gamma.800',
      borderRadius: 'base',
      color: 'gamma.800',
      fontSize: '2xs',
      fontWeight: 'bold',
      h: 'auto',
      minW: 'auto',
      px: '2.5',
      py: '1',
      bg: 'alpha.500',
      boxShadow: '0px 2px 4px 0px rgba(0, 0, 0, 0.25)',
      textTransform: 'uppercase',
      _active: {
        bg: 'gamma.800',
        color: 'white',
        boxShadow: '0px 2px 3px 0px rgba(0, 0, 0, 0.25)',
      },
    },
    segment: {
      borderRadius: 'base',
      fontSize: '2xs',
      fontWeight: 'bold',
      h: 'auto',
      px: '3',
      py: '3px',
      textTransform: 'uppercase',
      _hover: {
        color: 'alpha.500',
      },
      _active: {
        bgGradient: 'linear(225deg, alpha.500, alpha.500)',
        boxShadow:
          '0px 1px 0px 0px rgba(255, 255, 255, 0.60) inset, 0px 1px 2px 0px rgba(0, 0, 0, 0.25)',
        color: 'blackAlpha.700',
        textShadow: '0px 1px 0px rgba(255, 255, 255, 0.30)',
      },
    },
    tab: {
      color: 'gamma.800',
      bg: 'linear-gradient(var(--bc-colors-alpha-500) 0%, var(--bc-colors-gamma-500) 100%)',
      textShadow: '0px 1px 0px rgba(255, 255, 255, 0.3)',
      letterSpacing: 'wider',
      fontWeight: 'extrabold',
      fontSize: '2xs',
      textTransform: 'uppercase',
      borderRadius: 'base',
      px: '2',
      py: '1',
      minW: 'fit-content',
      h: '6',
      transition: 'all .2s ease-in-out',
      boxShadow: '0px 2px 5px rgba(0, 0, 0, 0.15)',
      '&:hover': {
        color: 'gamma.800',
        bg: 'linear-gradient(var(--bc-colors-alpha-500) 0%, var(--bc-colors-alpha-500) 100%)',
      },

      '&:active, &[data-active="true"]': {
        color: 'white',
        bg: 'blackAlpha.500',
        boxShadow:
          '0px 1px 0px rgba(255, 255, 255, 0.1), inset 0px 2px 4px rgba(0, 0, 0, 0.2)',

        '&:hover': {
          bg: 'alpha.500',
          boxShadow:
            '0px 1px 0px rgba(255, 255, 255, 0.1), inset 0px 2px 4px rgba(0, 0, 0, 0.2)',
        },
      },
    },
  },
  defaultProps: {
    size: 'md',
    p: '5',
    colorScheme: null,
  },
};
