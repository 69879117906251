import { CSSObject } from '@chakra-ui/react';
import { ExoticsActionSchema } from '@/views/races/bets/Exotics/components/Action/styles/Action.styles';

export const exoticsActionStyles: ExoticsActionSchema = {
  buttonExoticsActionProps: {
    variant: 'solid',
    boxSize: '9',
  },
};

export const FlexOdds: CSSObject = {
  minW: ['38px', null, '14'],
  height: '8',
  p: '2',
  borderRadius: 'md',
  fontWeight: 'bold',
  fontSize: 'sm',
  bg: 'gray.300',
  borderWidth: '2px',
  borderColor: 'white',
  color: 'blackAlpha.500',
  textShadow: '0px 1px 0px rgba(255, 255, 255, 0.15)',
  boxShadow:
    '0px 0px 0px 2px rgba(0, 0, 0, 0.30) inset, 0px 2px 6px 0px rgba(0, 0, 0, 0.35)',
  opacity: '1',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
};

export const ActionsContainer: CSSObject = {
  display: 'flex',
  flexDir: 'row',
  gap: '1',
  mb: '1',
};

export const HStackActions: CSSObject = {
  gap: '1',
  alignItems: ['flex-end', 'center'],
};
