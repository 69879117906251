import { CSSObject } from '@chakra-ui/react';

export const DeductionsText: CSSObject = {
  color: 'whiteAlpha.700',
  mb: '1',
  fontSize: '2xs',
  fontWeight: 'semibold',
};

export const FooterMoreOpenText: CSSObject = {
  color: 'alpha.500',
  fontSize: 'xs',
  fontWeight: 'bold',
  borderTop: '1px dashed',
  borderBottom: '1px dashed',
  // TODO: This value and below blackAlpha should be identical value. But look vastly different. Needs review
  borderColor: 'blackAlpha.200',
  mx: '2',

  '&&': {
    px: '0',
  },
};

export const BoxMoreText: CSSObject = {
  w: '8',
};

export const DividerMulti: CSSObject = {
  borderStyle: 'dashed',
  borderColor: 'blackAlpha.300',
  mx: '2',
};
