import { CSSObject } from '@chakra-ui/react';
import { CustomButtonProps } from '@/components/Button/Button';

export const buttonAddToBetslipProps: CustomButtonProps = {
  variant: 'secondary',
};

export const FlexWrapper: CSSObject = {
  px: ['3', null, '2.5'],
  '&&': {
    py: '3',
  },
};

export const FlexInputWrapper: CSSObject = {
  label: {
    color: 'white',
    fontWeight: 'bold',
  },

  '.chakra-input': {
    transition: '.3s ease-in-out',
    bg: 'epsilon.500',
    border: '2px',
    borderColor: 'epsilon.200',
    color: 'white',
    borderRightRadius: 'lg',

    _placeholder: {
      color: 'gray.400',
    },

    _hover: {
      bg: 'gray.600',
    },
  },

  '.chakra-input__left-addon': {
    border: 'none',
    fontWeight: 'extrabold',
    bg: 'epsilon.200',
    borderLeftRadius: 'lg',
  },
};

export const SelectionPopupContainer: CSSObject = {
  my: [null, null, '4'],
  mx: [null, null, '4'],
  mt: '4',
  bg: 'epsilon.500',
  borderRadius: 'base',
  zIndex: 'docked',
};

export const InfoHeader: CSSObject = {
  bg: 'blackAlpha.300',
  h: '7',
  px: '2',
  py: '1.5',
};

export const InfoText: CSSObject = {
  fontSize: 'xs',
  color: 'white',
  fontWeight: 'medium',
};

export const TextAddToBetslipButton: CSSObject = {
  letterSpacing: 'wider',
  w: '240px',
  fontWeight: 'normal',
};
