import { CSSObject } from '@chakra-ui/react';

export const DeductionsText: CSSObject = {
  color: 'epsilon.500',
  mb: '1',
};

export const FooterMoreOpenText: CSSObject = {
  color: 'epsilon.500',
  fontSize: 'xs',
};

export const BoxMoreText: CSSObject = {
  w: '8',
};
