import { CSSObject } from '@chakra-ui/react';
import { RaceTableSchema } from '@/views/races/RaceMeetings/RaceTable/styles/RaceTable/RaceTable.styles';

export const raceTableStyles: RaceTableSchema = {
  iconSvgMoneyBackProps: { color: 'beta.500', mr: ['20', 0] },
  afterLinkRaceItemProps: { bg: [null, null, 'beta.500'] },
};

export const FlexHeadingRow: CSSObject = {};

export const FlexHeading: CSSObject = {
  bgGradient: 'linear(to-b, #FFE6B6, beta.500)',
  color: 'beta.600',
  top: ['12', '84px'],
  position: 'unset',
  mb: '0',
};

export const TextHeading: CSSObject = {
  fontFamily: 'Roboto',
  fontSize: '13px',
  fontStyle: 'normal',
  fontWeight: 600,
  lineHeight: '133%',
};

export const IconHeading: CSSObject = {
  boxSize: '1.8rem',
  color: 'beta.300',
  bgGradient: 'linear(to-b, gamma.500, gamma.700)',
  borderRadius: 'base',
  boxShadow:
    '0px 2px 5px 0px rgba(0, 0, 0, 0.25), 0px 1px 1px 0.5px #9F9CFD inset',
  p: '1.5',
  mr: '2',
};

export const BoxPageWrapper: CSSObject = {
  m: '0',
  mb: '4',
  p: '0.5',
  borderRadius: 'base',
  border: '2px',
  borderColor: '#FFE6B6',
  boxShadow: '0px 2px 5px 0px rgba(0, 0, 0, 0.35), 0px 0px 0px 4px #000 inset',
};

export const BoxTableWrapper: CSSObject = {
  p: '0',
};

export const TextVenueName: CSSObject = {
  fontWeight: 'medium',
  color: 'gray.700',
};

export const TextVenueCountry: CSSObject = {
  fontWeight: 'medium',
  color: 'beta.500',
};

export const TextRaceResults: CSSObject = {
  color: 'gray.700',
};

export const BadgeRaceItem = ({ state }: any): CSSObject => ({
  borderRadius: 'base',
  bg: (() => {
    if (state === 'result') {
      return 'black';
    }
    return 'blackAlpha.400';
  })(),
});

export const LinkRaceItem = () => ({
  borderLeft: 'none',
  borderTop: 'none',
  borderBottom: '1px solid',
  borderRightWidth: '1px solid',
  borderRightColor: 'gray.300',
  borderBottomColor: 'gray.300',
  _hover: {
    background: ['transparent', null, 'white'],
  },
  _last: {
    borderRight: 'none',
  },
});

export const FlexRow: CSSObject = {
  bg: 'white',
  mb: '0',
  borderRadius: 'none',

  _last: {
    borderBottom: 'none',
  },
};

export const FlexButtonWrapper: CSSObject = {
  ml: 'auto',
  flexDir: 'column',
  columnGap: '4',
};

export const BoxVenueName: CSSObject = {
  bg: 'white',
  py: '1.5',
  borderColor: 'unset',
  borderTop: 'none',
  borderLeft: 'none',
  borderRight: '1px solid',
  borderRightColor: 'gray.300',
  borderBottom: '1px solid',
  borderBottomColor: 'gray.300',
};

export const CenterTH = () => ({
  bg: 'beta.600',
  color: 'white',
  textAlign: 'center',
  fontFamily: 'Roboto',
  fontSize: '10px',
  fontStyle: 'normal',
  fontWeight: 500,
  lineHeight: 'normal',
  py: '2.5',
  borderBottom: '1px',
  borderColor: 'gray.300',
});

export const ButtonChevron: CSSObject = {
  color: 'blackAlpha.700',
  bg: 'beta.300',
  borderRadius: 'base',
  boxSize: '5',
  width: '7',
  _first: {
    '&&': {
      mb: '0.5',
    },
  },
};
