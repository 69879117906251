import { CSSObject } from '@chakra-ui/react';
import { RaceDetailsSchema } from '@/views/races/RaceDetails/styles/RaceDetails.styles';

export const raceDetailsStyles: RaceDetailsSchema = {
  buttonStyledGroupedProps: {
    minH: 'unset',
    minW: 'unset',
    boxSize: '8',
    lineHeight: 'normal',
    sx: {
      '&[data-hasPromo="true"]': {
        minW: 12,
        '.btn-span': {
          pl: 0.5,
        },
      },
      '&[data-active="true"]': {
        '&&': {
          bg: 'alpha.800',
          boxShadow:
            '0px 1px 0px 0px rgba(255, 255, 255, 0.15), 0px 2px 4px 0px rgba(0, 0, 0, 0.40) inset',
          color: 'beta.500',
          textShadow: '0px 1px 0px rgba(0, 0, 0, 0.35)',
          border: 'none',
        },
      },

      '&[data-closed="true"]': {
        boxShadow:
          '0px 0px 0px 2px rgba(0, 0, 0, 0.20) inset, 0px 2px 6px 0px rgba(0, 0, 0, 0.35)',
        bg: 'gray.300',
        color: 'blackAlpha.400',
        textShadow: 'unset',
        px: '3',
      },
    },
  },
};

export const TextRaceConditions: CSSObject = {
  _after: {
    content: `""`,
    boxSize: '1',
    borderRadius: 'full',
    pos: 'relative',
    mx: '1.5',
    bg: 'alpha.500',
  },
};

export const RaceAndTimerContainer: CSSObject = {
  bg: ['transparent', 'transparent', 'blackAlpha.400'],
  borderRadius: 'base',
  boxShadow: [
    null,
    null,
    '0px 1px 0px 0px rgba(255, 255, 255, 0.1), 0px 2px 5px 0px rgba(0, 0, 0, 0.35) inset',
  ],
  mb: ['1.5', null],
};

export const FlexPageHeaderTitleWrapper: CSSObject = {
  mx: ['0', '2'],
};

export const RaceHeaderWrapper: CSSObject = {
  '& .state-inprogress': {
    color: 'black',
  },
  ml: ['2', '0'],
};

export const RaceName: CSSObject = {
  fontWeight: 'bold',
  fontSize: ['2xs', 'xs'],
  color: 'white',
  textTransform: 'capitalize',
};

export const InfoContainer: CSSObject = {
  py: '2',
  my: '-2',
};

export const RaceRunnerList: CSSObject = {
  p: '2',
  bg: 'white',
  mx: '4',
  mb: '4',
  borderRadius: 'base',
  boxShadow: '0px 3px 8px rgba(0, 0, 0, 0.15)',
};

export const RaceRunnerListHeadings: CSSObject = {
  mx: '2',
};

export const RaceDetailsHeading: CSSObject = {
  textTransform: 'capitalize',
  color: 'gray.700',
  fontSize: 'xs',
  py: '2',
  fontWeight: 'medium',
};

export const TextTitle: CSSObject = {
  fontWeight: 'medium',
  fontSize: 'md',
  textTransform: 'capitalize',
  color: 'white',
};

export const ResultedStatusLabel: CSSObject = {
  bg: 'beta.600',
  color: 'beta.900',
};

export const RaceStatusText: CSSObject = {
  bg: 'orange.500',
};

export const RaceRunnerWinPlaceContainer: CSSObject = {
  columnGap: '1',
  fontSize: ['2xs', null, 'sm'],
  fontWeight: 'medium',
};

export const RaceRunnerPropositionHeadingContainer: CSSObject = {
  color: 'black',
  fontSize: ['2xs', null, 'sm'],
  '&&': {
    w: '12',
    p: '0',
  },
};

export const RaceResultListWrapper: CSSObject = {
  mb: ['3', '0', null],
  mx: '4',
};

export const FlexHeaderMetaWrapper: CSSObject = {
  '.state-prior': {
    color: 'white',
  },
};

export const CountdownTimerContainer: CSSObject = {
  borderRadius: '3px',

  '.chakra-badge.state-prior': {
    px: '2',
    py: '1',
    bg: 'blackAlpha.400',
    color: 'white',
  },
};

export const ButtonGroupWrapper: CSSObject = {
  ml: ['0', '2'],
  gap: '1',
};

export const RaceRunnerHeadingContainer: CSSObject = {
  '.chakra-switch__track': {
    background: 'gray.400',
    '& .chakra-switch__thumb': {
      background: 'white',
    },
    '&[data-checked]': {
      background: 'gamma.500',
    },
  },
  columnGap: '0',
  svg: {
    color: 'white',
  },
};
