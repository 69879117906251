import { CSSObject } from '@chakra-ui/react';
import { CustomButtonProps } from '@/components/Button/Button';

export const InputContainer: CSSObject = {
  '& .chakra-checkbox__label': {
    color: 'white',
  },
  '& .chakra-form__label': {
    color: 'white',
  },
  '#period-select': {
    border: 'none',
    color: 'white',
    bg: 'alpha.300',
    boxShadow:
      '0px 1px 5px 0px rgba(0, 0, 0, 0.15) inset, 0px 1px 0px 0px rgba(255, 255, 255, 0.1)',
    'svg, .chakra-select__icon': {
      color: 'beta.400',
    },
  },
};
export const buttonConfirmBreakProps: CustomButtonProps = {
  pt: '1',
  variant: 'primary',
  textTransform: 'uppercase',
  fontSize: 'xs',
};
export const TextConfirmBreak: CSSObject = {
  fontSize: 'xs',
  fontWeight: 'semibold',
};
export const TextDate: CSSObject = {
  fontSize: 'md',
  color: 'beta.400',
};
export const TextDateLabel: CSSObject = {
  fontSize: 'xs',
  fontWeight: 'semibold',
};
export const BoxTakeABreak: CSSObject = {
  p: '0',
  m: '0',
};
export const buttonTakeBreakProps: CustomButtonProps = {
  height: '38px',
  pt: '1',
  variant: 'solid',
  lineHeight: 'normal',
  textTransform: 'uppercase',
  fontSize: 'xs',
};
export const ListItemExclusionItem: CSSObject = {
  fontSize: 'xs',
  color: 'white',
};
export const HeadingInformation: CSSObject = {
  fontWeight: 'medium',
  mb: '0',
  fontSize: 'sm',
  color: 'white',
};
export const TextInformation: CSSObject = {
  fontSize: 'xs',
  mb: '3',
  color: 'white',
  '&:first-of-type': {
    fontWeight: 'bold',
  },
};
export const BoxStyledLink: CSSObject = {
  color: 'beta.500',
};
export const TextM: CSSObject = {
  fontSize: 'sm',
  color: 'white',
  fontWeight: 'medium',
  mb: '0',
};
export const BoxTakeABreakWrapper: CSSObject = {
  pb: 0,
  border: 'none',
};
export const TakeABreakContainer: CSSObject = {
  color: 'white',
  p: '0',
  b: '0',
  mb: '3',
  fontSize: 'xs',
  strong: {
    fontSize: 'sm',
  },
};
export const TextS: CSSObject = {
  color: 'white',
  pt: '2',
  m: '0',
  fontSize: '12px',
  pb: '1',
};
